import { DocumentMapper } from "@core/data/mappers/document.mapper";
import { Nullable } from "@core/domain/types/nullable.type";
import { convertEmptyToUndefined } from "@core/domain/types/undefinable.type";
import { CostIVATypeEnumMapper } from "@entity/data/mappers/cost/cost-iva-type-enum.mapper";
import { ServiceProvidedEnumMapper } from "@entity/data/mappers/supplier/service-provided-enum.mapper";
import { CostSummary } from "@entity/domain/models/cost/cost-summary.model";
import {
    AdditionalExpenseCreate,
    Cost,
} from "@entity/domain/models/cost/cost.model";
import { validateSync } from "class-validator";
import { inject, injectable } from "inversify";
import { DateTime } from "luxon";
import { CostDto } from "../../dto/cost/cost.dto";
import { IdentificationEnumMapper } from "../deposit/identification-enum.mapper";
import { CostAdditionalExpensesMapper } from "./cost-additional-expenses.mapper";
import { CostTypeEnumMapper } from "./cost-type-enum.mapper";
import { IRPFTypeEnumMapper } from "./irpf-type-enum.mapper";
import { PaymentMethodTypeEnumMapper } from "./payment-method-type.mapper";

@injectable()
export class CostMapper {
    constructor(
        @inject(IdentificationEnumMapper)
        private readonly identificationEnumMapper: IdentificationEnumMapper,
        @inject(PaymentMethodTypeEnumMapper)
        private readonly paymentMethodTypeEnumMapper: PaymentMethodTypeEnumMapper,
        @inject(CostTypeEnumMapper)
        private readonly costTypeEnumMapper: CostTypeEnumMapper,
        @inject(CostIVATypeEnumMapper)
        private readonly costIvaTypeEnumMapper: CostIVATypeEnumMapper,
        @inject(IRPFTypeEnumMapper)
        private readonly irpfTypeEnumMapper: IRPFTypeEnumMapper,
        @inject(CostAdditionalExpensesMapper)
        private readonly costAdditionalExpensesMapper: CostAdditionalExpensesMapper,
        @inject(DocumentMapper)
        private readonly documentMapper: DocumentMapper,
        @inject(ServiceProvidedEnumMapper)
        private serviceProvidedEnumMapper: ServiceProvidedEnumMapper,
    ) {}

    map(costDto: CostDto): Nullable<Cost> {
        const errors = validateSync(costDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const identificationType = this.identificationEnumMapper.map(
            costDto.issuer_identification_type,
        );

        const paymentMethod = this.paymentMethodTypeEnumMapper.map(
            costDto.payment_method,
        );

        const costType = this.costTypeEnumMapper.map(costDto.type);

        const iva = this.costIvaTypeEnumMapper.map(costDto.iva);

        if (!identificationType || !iva || !paymentMethod || !costType) {
            return null;
        }

        let irpf = null;
        if (costDto.irpf_type)
            irpf = this.irpfTypeEnumMapper.map(costDto.irpf_type);

        let additionalExpenses = undefined;
        if (costDto.additional_expenses_data) {
            additionalExpenses = costDto.additional_expenses_data.mapNotNull(
                (expense) => this.costAdditionalExpensesMapper.map(expense),
            );
        }

        const document = costDto.document_data
            ? this.documentMapper.map(costDto.document_data)
            : undefined;

        const additionalExpenseCreate: AdditionalExpenseCreate[] =
            costDto.additional_expenses_data?.map((expenseData) => ({
                project: expenseData.project,
                invoice: expenseData.project,
                amount: expenseData.amount,
            })) ?? [];

        const cost = new Cost(
            costDto.id,
            costDto.entity,
            costDto.number,
            costDto.concept,
            costDto.issuer_name,
            identificationType,
            costDto.issuer_identification_number,
            costType,
            DateTime.fromISO(costDto.issue_date),
            DateTime.fromISO(costDto.payment_date),
            paymentMethod,
            costDto.base,
            iva,
            parseFloat(costDto.iva_other),
            costDto.total,
            costDto.discount,
            irpf,
            costDto.others_type,
            document,
            additionalExpenseCreate,
            additionalExpenses,
        );

        if (costDto.categories) {
            cost.category = costDto.categories.mapNotNull((serviceProv) =>
                this.serviceProvidedEnumMapper.map(serviceProv),
            );
        }

        cost.otherCategory = convertEmptyToUndefined(costDto.other_category);
        cost.otherPaymentMethod = convertEmptyToUndefined(
            costDto.other_payment_method,
        );

        return cost;
    }

    mapToCostSummary(costDto: CostDto): Nullable<CostSummary> {
        const errors = validateSync(costDto);

        if (errors.length) {
            console.error(errors);

            return null;
        }

        const costType = this.costTypeEnumMapper.map(costDto.type);

        if (!costType) {
            return null;
        }

        return new CostSummary(
            costDto.id,
            costDto.entity,
            costDto.number,
            costType,
            costDto.concept,
            DateTime.fromISO(costDto.issue_date),
        );
    }
}
